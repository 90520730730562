import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getDefaultStartDate, getDefaultEndDate } from "../../common/helper";

const defaultStartDate = getDefaultStartDate().toDate().toISOString();
const defaultEndDate = getDefaultEndDate().toDate().toISOString();

const initialState = {
  dateRange: {
    dateStart: defaultStartDate,
    dateEnd: defaultEndDate,
  },
  sleepCountPieChart: {
    data: [],
    status: "idle",
  },
  eventCountByDay: {
    data: {
      seizureCount: [],
      nonSeizureCount: [],
    },
    status: "idle",
  },
  eventCountByWeek: {
    data: {
      seizureCount: [],
      nonSeizureCount: [],
    },
    status: "idle",
  },
  eventCountByMonth: {
    data: {
      seizureCount: [],
      nonSeizureCount: [],
    },
    status: "idle",
  },
  eventCountByHour: {
    data: {
      seizureCount: [],
      nonSeizureCount: [],
    },
    status: "idle",
  },
  eventCountBySymptom: {
    data: {
      seizureCount: [],
      nonSeizureCount: [],
    },
    status: "idle",
  },
  seizureDurationCount: {
    data: [],
    status: "idle",
  },
};

export const fetchSleepCountData = createAsyncThunk(
  "dashboard/fetchSleepCountData",
  async (params) => {
    const { dateStart, dateEnd } = params;
    const response = await axios.get(
      `/api/reportSleepCount?dateStart=${dateStart}&dateEnd=${dateEnd}`
    );
    return response.data;
  }
);

export const fetchEventCountByDay = createAsyncThunk(
  "dashboard/fetchEventCountByDay",
  async (params) => {
    const { dateStart, dateEnd } = params;
    const url = `/api/reportEventCountByDay?dateStart=${dateStart}&dateEnd=${dateEnd}`;
    const response = await axios.get(url);
    return response.data;
  }
);

export const fetchEventCountByWeek = createAsyncThunk(
  "dashboard/fetchEventCountByWeek",
  async (params) => {
    const { dateStart, dateEnd } = params;
    const response = await axios.get(
      `/api/reportEventCountByWeek?dateStart=${dateStart}&dateEnd=${dateEnd}`
    );
    return response.data;
  }
);

export const fetchEventCountByMonth = createAsyncThunk(
  "dashboard/fetchEventCountByMonth",
  async (params) => {
    const { dateStart, dateEnd } = params;
    const response = await axios.get(
      `/api/reportEventCountByMonth?dateStart=${dateStart}&dateEnd=${dateEnd}`
    );
    return response.data;
  }
);

export const fetchEventCountByHour = createAsyncThunk(
  "dashboard/fetchEventCountByHour",
  async (params) => {
    const { dateStart, dateEnd } = params;
    const response = await axios.get(
      `/api/reportEventCountByHoursOfDay?dateStart=${dateStart}&dateEnd=${dateEnd}`
    );
    return response.data;
  }
);

export const fetchEventCountBySymptom = createAsyncThunk(
  "dashboard/fetchEventCountBySymptom",
  async () => {
    const response = await axios.get(`/api/reportEventCountBySymptom`);
    return response.data;
  }
);

export const fetchSeizureDurationCount = createAsyncThunk(
  "dashboard/fetchSeizureDurationCount",
  async (params) => {
    const { dateStart, dateEnd } = params;
    const response = await axios.get(
      `/api/reportSeizureDurationCount?dateStart=${dateStart}&dateEnd=${dateEnd}`
    );
    return response.data;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dateStartUpdated(state, action) {
      state.dateRange.dateStart = action.payload;
    },
    dateEndUpdated(state, action) {
      state.dateRange.dateEnd = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSleepCountData.pending, (state) => {
        state.sleepCountPieChart.status = "loading";
      })
      .addCase(fetchSleepCountData.fulfilled, (state, action) => {
        state.sleepCountPieChart.status = "succeeded";
        state.sleepCountPieChart.data = action.payload;
      })
      .addCase(fetchEventCountByDay.pending, (state) => {
        state.eventCountByDay.status = "loading";
      })
      .addCase(fetchEventCountByDay.fulfilled, (state, action) => {
        state.eventCountByDay.status = "succeeded";
        state.eventCountByDay.data = action.payload;
      })
      .addCase(fetchEventCountByWeek.pending, (state) => {
        state.eventCountByWeek.status = "loading";
      })
      .addCase(fetchEventCountByWeek.fulfilled, (state, action) => {
        state.eventCountByWeek.status = "succeeded";
        state.eventCountByWeek.data = action.payload;
      })
      .addCase(fetchEventCountByMonth.pending, (state) => {
        state.eventCountByMonth.status = "loading";
      })
      .addCase(fetchEventCountByMonth.fulfilled, (state, action) => {
        state.eventCountByMonth.status = "succeeded";
        state.eventCountByMonth.data = action.payload;
      })
      .addCase(fetchEventCountByHour.pending, (state) => {
        state.eventCountByHour.status = "loading";
      })
      .addCase(fetchEventCountByHour.fulfilled, (state, action) => {
        state.eventCountByHour.status = "succeeded";
        state.eventCountByHour.data = action.payload;
      })
      .addCase(fetchEventCountBySymptom.pending, (state) => {
        state.eventCountBySymptom.status = "loading";
      })
      .addCase(fetchEventCountBySymptom.fulfilled, (state, action) => {
        state.eventCountBySymptom.status = "succeeded";
        state.eventCountBySymptom.data = action.payload;
      })
      .addCase(fetchSeizureDurationCount.pending, (state) => {
        state.seizureDurationCount.status = "loading";
      })
      .addCase(fetchSeizureDurationCount.fulfilled, (state, action) => {
        state.seizureDurationCount.status = "succeeded";
        state.seizureDurationCount.data = action.payload;
      });
  },
});

export const { dateStartUpdated, dateEndUpdated } = dashboardSlice.actions;

export default dashboardSlice.reducer;

export const selectDateStart = (state) => state.dashboard.dateRange.dateStart;
export const selectDateEnd = (state) => state.dashboard.dateRange.dateEnd;
export const selectSleepCountData = (state) =>
  state.dashboard.sleepCountPieChart;
export const selectEventCountByDay = (state) => state.dashboard.eventCountByDay;
export const selectEventCountByWeek = (state) =>
  state.dashboard.eventCountByWeek;
export const selectEventCountByMonth = (state) =>
  state.dashboard.eventCountByMonth;
export const selectEventCountByHour = (state) =>
  state.dashboard.eventCountByHour;
export const selectEventCountBySymptom = (state) =>
  state.dashboard.eventCountBySymptom;
export const selectSeizureDurationCount = (state) =>
  state.dashboard.seizureDurationCount;
export const selectStatisticData = (state) => {
  const dailyData = state.dashboard.eventCountByDay.data;
  const seizureCount = dailyData.seizureCount;
  const nonSeizureCount = dailyData.nonSeizureCount;
  const totalDayCount = seizureCount.length;
  const seizureDayCount = seizureCount.filter(
    (item) => item.count !== 0
  ).length;
  const nonSeizureDayCount = nonSeizureCount.filter(
    (item) => item.count !== 0
  ).length;
  const goodDayCount = seizureCount
    .map((item, index) => {
      const addedCount = item.count + nonSeizureCount[index].count;
      return { date: item.date, count: addedCount };
    })
    .filter((item) => item.count === 0).length;
  const totalSeizureCount = seizureCount.map(item => item.count).reduce((pre, cur) => pre + cur, 0);
  const totalNonseizureCount = nonSeizureCount.map(item => item.count).reduce((pre, cur) => pre + cur, 0);
  const status = state.dashboard.eventCountByDay.status;
  return {
    status,
    data: {
      totalDayCount,
      seizureDayCount,
      nonSeizureDayCount,
      goodDayCount,
      totalSeizureCount,
      totalNonseizureCount,
    },
  };
};
