import React, { useState } from "react";
import styled from "styled-components";
import { ResponsiveLine } from "@nivo/line";
import { CheckboxToggle } from "react-rainbow-components";

const ChartPanel = styled.div`
  flex: 1 1 0%;
  min-width: 0;
  align-items: center;
`;

const ChartContainer = styled.div`
  height: 250px;
  width: 99%;
`;

const TitleBar = styled.div`
  display: flex;
`;

const ToolBar = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 10px;
`;

const Title = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  flex-grow: 1;
  margin-bottom: 0;
`;

const Loading = styled.div`
  text-align: center;
`;

const commonProperties = {
  margin: { top: 25, right: 20, bottom: 70, left: 50 },
  animate: true,
  enableSlices: "x",
};

export const EventCountByMonthLineChart = ({ reportData }) => {
  const [showPointLabel, setShowPointLabel] = useState(true);
  const [isStacked, setIsStacked] = useState(false);

  const handleShowPointValueButtonChange = () => {
    setShowPointLabel(!showPointLabel);
  };

  const handleStackedButtonChange = () => {
    setIsStacked(!isStacked);
  };

  let content;
  if (reportData.status === "succeeded") {
    const data = [
      {
        id: "发作",
        data: reportData.data.seizureCount.map((item) => ({
          x: item.date,
          y: item.count,
        })),
      },
      {
        id: "疑似发作，不舒服",
        data: reportData.data.nonSeizureCount.map((item) => ({
          x: item.date,
          y: item.count,
        })),
      },
    ];

    content = (
      <ResponsiveLine
        {...commonProperties}
        data={data}
        yScale={{
          type: "linear",
          stacked: isStacked,
        }}
        axisLeft={{
          legend: "发作次数",
          legendOffset: 12,
        }}
        axisBottom={{
          legend: "月份",
          legendOffset: -12,
          tickRotation: -30,
        }}
        curve="monotoneX"
        enablePointLabel={showPointLabel}
        pointSize={4}
        pointBorderWidth={1}
        pointBorderColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        useMesh={true}
        enableSlices={false}
        colors={{ scheme: "category10" }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 40,
            translateY: 70,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 150,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 18,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    );
  } else {
    content = <Loading>加载中......</Loading>;
  }
  return (
    <ChartPanel>
      <TitleBar>
        <Title>发作次数按月统计</Title>
      </TitleBar>
      <ToolBar>
        <div>
          <CheckboxToggle
            label="数值"
            value={showPointLabel}
            onChange={handleShowPointValueButtonChange}
          />
        </div>
        <div>
          <CheckboxToggle
            label="叠加"
            value={isStacked}
            onChange={handleStackedButtonChange}
          />
        </div>
      </ToolBar>
      <ChartContainer>{content}</ChartContainer>
    </ChartPanel>
  );
};
