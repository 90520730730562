import React from "react";
import { ResponsivePie } from "@nivo/pie";
import styled from "styled-components";

const ChartPanel = styled.div`
  flex: 1 1 0%;
  min-width: 0;
  align-items: center;
`;

const ChartContainer = styled.div`
  height: 230px;
  width: 99%;
`;

const Title = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

const Loading = styled.div`
  text-align: center;
`;

export const SeizureDurationCountPieChart = ({ reportData }) => {
  let content;

  if (reportData.status === "succeeded") {
    const data = reportData.data.map((item) => ({
      id: `${item.duration}分钟`,
      label: `${item.duration}分钟`,
      value: item.count,
    }));

    content = (
      <ResponsivePie
        data={data}
        colors={{ scheme: "nivo" }}
        margin={{ top: 10, right: 80, bottom: 50, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 35,
            translateY: 40,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    );
  } else {
    content = <Loading>加载中......</Loading>;
  }
  return (
    <ChartPanel>
      <Title>发作时长的次数</Title>
      <ChartContainer>{content}</ChartContainer>
    </ChartPanel>
  );
};
