import {
  createSlice,
  createAsyncThunk,
  // createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import axios from "axios";

const eventsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.startDateTime.localeCompare(a.startDateTime),
  selectId: (entity) => entity._id,
});

const initialState = eventsAdapter.getInitialState({
  status: "idle",
  error: null,
  pageInfo: {hasNextPage: false, endCursor: null},
});

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async (params) => {
    let limit = 20;
    let cursor = null;
    if (params) {
      limit = params.limit;
      cursor = params.cursor;
    }
    const response = await axios.get(
      `/api/events?limit=${limit}` + (cursor ? `&cursor=${cursor}` : "")
    );
    return response.data;
  }
);

export const addEvent = createAsyncThunk("events/addEvent", async (event) => {
  const response = await axios.post("/api/events", event);
  return response.data;
});

export const updateEvent = createAsyncThunk(
  "events/updateEvent",
  async (event) => {
    const response = await axios.put(`/api/events/${event._id}`, event);
    return { id: response.data._id, changes: response.data };
  }
);

export const deleteEvent = createAsyncThunk(
  "events/deleteEvent",
  async (id) => {
    const response = await axios.delete(`/api/events/${id}`);
    return response.data._id;
  }
);

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pageInfo = action.payload.pageInfo;
        eventsAdapter.upsertMany(state, action.payload.events);
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addEvent.fulfilled, eventsAdapter.addOne)
      .addCase(updateEvent.fulfilled, eventsAdapter.updateOne)
      .addCase(deleteEvent.fulfilled, eventsAdapter.removeOne);
  },
});

export default eventsSlice.reducer;

export const {
  selectAll: selectAllEvents,
  selectById: selectEventById,
  selectIds: selectEventIds,
} = eventsAdapter.getSelectors((state) => state.events);
