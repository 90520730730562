import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./features/app/appSlice";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import eventsReducer from "./features/events/eventsSlice";
import symptomsReducer from "./features/symptoms/symptomsSlice";

export default configureStore({
  reducer: {
    app: appReducer,
    dashboard: dashboardReducer,
    events: eventsReducer,
    symptoms: symptomsReducer,
  },
});
