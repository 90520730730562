import React from "react";
import { useSelector } from "react-redux";
import { Button } from "react-rainbow-components";
import { selectEventById } from "./eventsSlice";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFrown,
  faHeartbeat,
  faBed,
  faLungs,
  faDizzy,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

const StyledEventItem = styled.div`
  display: flex;
  column-gap: 16px;
  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
  min-height: 80px;
  padding: 8px 8px 8px 10px;
  border: 0.0625rem solid #e3e5ed;
  box-shadow: 0 1px 2px 0 #e3e5ed;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  row-gap: 3px;
`;

const MainIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  color: ${(props) =>
    props.seizure ? "rgb(230, 81, 0)" : "rgb(27, 188, 156)"};
`;

const HeartBeartIcon = styled(FontAwesomeIcon)`
  color: #d64929;
  font-size: 1rem;
`;

const SPO2Icon = styled(FontAwesomeIcon)`
  color: rgb(51, 151, 221);
  font-size: 1rem;
`;

const DurationIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`;

const SleepIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  color: #495057;
`;

const InfoBar = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

const DatePart = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
`;

const TimePart = styled.span`
  font-size: 1rem;
  font-weight: bold;
  margin-left: 5px;
`;

const Symptoms = styled.div`
  font-weight: bold;
`;

const DescriptionWrapper = styled.div`
  display: flex;
`;

const EditButtonWrapper = styled.div`
  display: flex;
  flex: 0 0 60px;
  flex-direction: row-reverse;
  height: 30px;
`;

const Description = styled.div`
  flex-grow: 1;
`;

const getDate = (datetimeString) => {
  const startDateTime = new Date(datetimeString);
  const month = startDateTime.getMonth() + 1;
  // const year = startDateTime.getFullYear();
  const day = startDateTime.getDate();
  return `${month}月${day}日`;
};

const getTime = (datatimeString) => {
  const startDateTime = new Date(datatimeString);
  return startDateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

const getDuration = (startDateTimeString, endDateTimeString) => {
  if (endDateTimeString) {
    const startDateTime = new Date(startDateTimeString);
    const endDateTime = new Date(endDateTimeString);
    const minutes = Math.round((endDateTime - startDateTime) / 60000);
    return minutes > 0 ? minutes : null;
  } else {
    return null;
  }
};

export const EventItem = ({ eventId, isAdmin }) => {
  const history = useHistory();
  const event = useSelector((state) => selectEventById(state, eventId));
  const startDate = getDate(event.startDateTime);
  const startTime = getTime(event.startDateTime);
  const duration = getDuration(event.startDateTime, event.endDateTime);

  return (
    <StyledEventItem>
      <Left>
        {event.isSeizure ? (
          <MainIcon icon={faDizzy} seizure="true" />
        ) : (
          <MainIcon icon={faFrown} />
        )}
      </Left>
      <Right>
        <TitleBar>
          <div>
            <DatePart>{startDate}</DatePart>
            <TimePart>{startTime}</TimePart>
          </div>
          <InfoBar>
            {duration && (
              <div title="持续时间">
                <DurationIcon icon={faClock} /> {duration} 分钟
              </div>
            )}
            {event.minSpo2 !== 0 && (
              <div title="最低血氧">
                <SPO2Icon icon={faLungs} /> {`${event.minSpo2}%`}
              </div>
            )}
            {event.maxHeartRate !== 0 && (
              <div title="最大心率">
                <HeartBeartIcon icon={faHeartbeat} /> {event.maxHeartRate}
              </div>
            )}
            {event.isSleeping && (
              <div title="睡觉时发作">
                <SleepIcon icon={faBed} />
              </div>
            )}
          </InfoBar>
        </TitleBar>
        <Symptoms>
          {event.symptoms.map((symptom) => symptom.name).join("; ")}
        </Symptoms>
        <DescriptionWrapper>
          <Description>{event.description}</Description>
          {isAdmin && (
            <EditButtonWrapper>
              <Button
                label="更改"
                variant="border-filled"
                size="small"
                onClick={() => history.push(`/events/edit/${eventId}`)}
              />
            </EditButtonWrapper>
          )}
        </DescriptionWrapper>
      </Right>
    </StyledEventItem>
  );
};
