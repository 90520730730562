import React from "react";
import styled from "styled-components";
import { ResponsiveBar } from "@nivo/bar";

const ChartPanel = styled.div`
  flex: 1 1 0%;
  min-width: 0;
  align-items: center;
`;

const ChartContainer = styled.div`
  height: 750px;
  width: 99%;
`;

const Title = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

const Loading = styled.div`
  text-align: center;
`;

export const EventCountBySymptomBarChart = ({ reportData }) => {
  let content;
  if (reportData.status === "succeeded") {
    const data = reportData.data.seizureCount.map((item) => ({
      symptom: item.symptom,
      发作: item.count,
    }));
    for (let item of reportData.data.nonSeizureCount) {
      const symptom = item.symptom;
      const itemFound = data.find((item) => item.symptom === symptom);
      if (itemFound) {
        itemFound["疑似发作"] = item.count;
      }
    }
    content = (
      <ResponsiveBar
        data={data}
        keys={["发作", "疑似发作"]}
        indexBy="symptom"
        margin={{ top: 20, right: 100, bottom: 50, left: 110 }}
        padding={0.3}
        groupMode="stacked"
        layout="horizontal"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "次数",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        labelSkipWidth={3}
        labelSkipHeight={2}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    );
  } else {
    content = <Loading>加载中......</Loading>;
  }

  return (
    <ChartPanel>
      <Title>发作症状次数(基于全部数据，与日期选择无关)</Title>
      <ChartContainer>{content}</ChartContainer>
    </ChartPanel>
  );
};
