import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  dateStartUpdated,
  dateEndUpdated,
  selectDateStart,
  selectDateEnd,
  selectSleepCountData,
  selectEventCountByDay,
  selectEventCountByWeek,
  selectEventCountByMonth,
  selectEventCountByHour,
  selectEventCountBySymptom,
  selectSeizureDurationCount,
  selectStatisticData,
} from "./dashboardSlice";
import {StatisticBoard} from "./StatisticBoard";
import { SleepCountPieChart } from "./SleepCountPieChart";
import { EventCountByDayLineChart } from "./EventCountByDayLineChart";
import { EventCountByWeekLineChart } from "./EventCountByWeekLineChart";
import { EventCountByMonthLineChart } from "./EventCountByMonthLineChart";
import { EventCountByHourLineChart } from "./EventCountByHourLineChart";
import { EventCountBySymptomBarChart } from "./EventCountBySymptomBarChart";
import { SeizureDurationCountPieChart } from "./SeizureDurationCountPieChart";

import "antd/dist/antd.css";
import { DatePicker } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";

const { RangePicker } = DatePicker;

const StyledDashboard = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding-left: 5px;
  min-width: 0;
`;

const Toolbar = styled.div`
  display: flex;
  background-color: white;
  margin: 0px 15px 10px 10px;
  padding: 10px;
  border: 0.0625rem solid #e3e5ed;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  @media (max-width: 800px) {
    margin-left: 0px;
    margin-right: 5px;
    flex-direction: column;
  }
`;

const RangePickerLabel = styled.label`
  font-size: 1.2rem;
`;

const ReportRow = styled.div`
  display: flex;
  padding: 5px 15px 5px 10px;
  column-gap: 10px;

  @media (max-width: 800px) {
    flex-direction: column;
    row-gap: 10px;
    padding-left: 0px;
    padding-right: 5px;
  }
`;
const ReportPanel = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  background-color: white;
  padding: 10px;
  border: 0.0625rem solid #e3e5ed;
  box-shadow: 0 1px 2px 0 #e3e5ed;
  border-radius: 0.875rem;
`;

export const Dashboard = () => {
  const dateStart = useSelector(selectDateStart);
  const dateEnd = useSelector(selectDateEnd);
  const statisticData = useSelector(selectStatisticData);
  const sleepCountData = useSelector(selectSleepCountData);
  const eventCountByDay = useSelector(selectEventCountByDay);
  const eventCountByWeek = useSelector(selectEventCountByWeek);
  const eventCountByMonth = useSelector(selectEventCountByMonth);
  const eventCountByHour = useSelector(selectEventCountByHour);
  const eventCountBySymptom = useSelector(selectEventCountBySymptom);
  const seizureDurationCount = useSelector(selectSeizureDurationCount);

  const dispatch = useDispatch();

  function onChange(dates) {
    if (dates && dates[0]) {
      dispatch(
        dateStartUpdated(dates[0].startOf("day").toDate().toISOString())
      );
    }
    if (dates && dates[1]) {
      dispatch(dateEndUpdated(dates[1].endOf("day").toDate().toISOString()));
    }
  }

  return (
    <StyledDashboard>
      <h1>统计分析</h1>
      <Toolbar>
        <RangePickerLabel>选择报表区间</RangePickerLabel>
        <RangePicker
          ranges={{
            过去1周: [
              moment().subtract(6, "days").startOf("day"),
              moment().endOf("day"),
            ],
            过去2周: [
              moment().subtract(13, "days").startOf("days"),
              moment().endOf("day"),
            ],
            过去1个月: [
              moment().subtract(29, "days").startOf("day"),
              moment().endOf("day"),
            ],
            过去2个月: [
              moment().subtract(59, "days").startOf("day"),
              moment().endOf("day"),
            ],
            过去3个月: [
              moment().subtract(89, "days").startOf("day"),
              moment().endOf("day"),
            ],
            全部天数: [
              moment("2021-07-29").startOf("day"),
              moment().endOf("day"),
            ],
          }}
          locale={locale}
          size="large"
          defaultValue={[
            moment(new Date(dateStart)),
            moment(new Date(dateEnd)),
          ]}
          onChange={onChange}
        />
      </Toolbar>
      <ReportRow>
        <ReportPanel>
          <StatisticBoard statisticData={statisticData} />
        </ReportPanel>
      </ReportRow>
      <ReportRow>
        <ReportPanel>
          <EventCountByDayLineChart reportData={eventCountByDay} />
        </ReportPanel>
      </ReportRow>
      <ReportRow>
        <ReportPanel>
          <EventCountByWeekLineChart reportData={eventCountByWeek} />
        </ReportPanel>
      </ReportRow>
      <ReportRow>
        <ReportPanel>
          <EventCountByMonthLineChart reportData={eventCountByMonth} />
        </ReportPanel>
      </ReportRow>
      <ReportRow>
        <ReportPanel>
          <EventCountByHourLineChart reportData={eventCountByHour} />
        </ReportPanel>
      </ReportRow>
      <ReportRow>
        <ReportPanel>
          <SleepCountPieChart reportData={sleepCountData} />
        </ReportPanel>
      </ReportRow>
      <ReportRow>
        <ReportPanel>
          <SeizureDurationCountPieChart reportData={seizureDurationCount} />
        </ReportPanel>
      </ReportRow>
      <ReportRow>
        <ReportPanel>
          <EventCountBySymptomBarChart reportData={eventCountBySymptom} />
        </ReportPanel>
      </ReportRow>
    </StyledDashboard>
  );
};
