import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { navigateTo } from "../../history";

const initialState = { isSidebarHidden: true, userInfo: {clientPrincipal: null} };

export const navigate = (path) => {
  const isMobileViewPort = document.body.offsetWidth < 600;
  return (dispatch) => {
    if (isMobileViewPort) {
      navigateTo(path);
      return dispatch(toggleSidebar());
    }
    return navigateTo(path);
  };
};

export const getUserInfo = createAsyncThunk("api/getUserInfo", async () => {
  try {
    const response = await axios.get("/.auth/me");
    return response.data;
  } catch (error) {
    console.error("No profile could be found");
    return null;
  }
});

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.isSidebarHidden = !state.isSidebarHidden;
    },
  },
  extraReducers(builder) {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
  },
});

export const { toggleSidebar } = appSlice.actions;

/*
const selectUserInfo = createSelector((state) => {
  console.log("state: ", state);
  return state.app.userInfo
});
export const selectUserId = createSelector(selectUserInfo, ({clientPrincipal}) => clientPrincipal && clientPrincipal.userId);
export const selectUserName = createSelector(selectUserInfo, ({clientPrincipal}) => clientPrincipal && clientPrincipal.userDetails);
*/

export const selectUserId = state => state.app.userInfo.clientPrincipal && state.app.userInfo.clientPrincipal.userId;
export const selectUserName = state => state.app.userInfo.clientPrincipal && state.app.userInfo.clientPrincipal.userDetails;
export const selectIsAdmin = state => {
  const clientPrincipal = state.app.userInfo.clientPrincipal;
  if (clientPrincipal) {
    const userRoles = clientPrincipal.userRoles;
    if (userRoles.indexOf("admin") !== -1) {
      return true;
    }
  }
  return false;
}

export default appSlice.reducer;
