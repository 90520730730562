import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-rainbow-components";
import { fetchEvents, selectEventIds } from "./eventsSlice";
import { selectIsAdmin  } from "../app/appSlice";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { EventItem } from "./EventItem";

const StyledInfiniteScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledEventsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1600px;
  padding-left: 5px;
  padding-right: 5px;
  row-gap: 6px;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  Button {
    margin-right: 10px;
  }
`;

export const EventsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAdmin = useSelector(selectIsAdmin);
  const eventIds = useSelector(selectEventIds);
  // const eventsLoadingStatus = useSelector((state) => state.events.status);
  const { hasNextPage, endCursor } = useSelector(
    (state) => state.events.pageInfo
  );

  // console.log("eventsLoadingStatus ", eventsLoadingStatus);

  const loadMore = () => {
    dispatch(fetchEvents({ limit: 20, cursor: endCursor }));
  };

  let content = eventIds.map((eventId) => (
    <EventItem eventId={eventId} key={eventId} isAdmin={isAdmin} />
  ));

  return (
    <StyledInfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasNextPage}
      loader={
        <div className="loader" key={0}>
          加载中......
        </div>
      }
    >
      <StyledEventsList>
        <TitleBar>
          <h1>所有事件</h1>
          {isAdmin && <div>
            <Button
              label="添加新事件"
              variant="brand"
              shaded
              onClick={() => history.push("/events/add")}
            />
          </div>}
        </TitleBar>
        {content}
      </StyledEventsList>
    </StyledInfiniteScroll>
  );
};
