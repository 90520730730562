import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Input } from "react-rainbow-components";
import { Textarea } from "react-rainbow-components";
import { Button } from "react-rainbow-components";
import { addSymptom } from "./symptomsSlice";

const StyledAddSymptom = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 1.4rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 600px;
  @media (max-width: 600px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const StyledInput = styled(Input)`
  max-width: 600px;
  margin-bottom: 10px;
`;

const StyledTextarea = styled(Textarea)`
  max-width: 600px;
  margin-bottom: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
`;

export const AddSymptom = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAddSymptom = async () => {
    if (!name) {
      setNameError("症状名不能为空");
    } else {
      nameError && setNameError("");
      setIsLoading(true);
      await dispatch(addSymptom({ name, description })).unwrap();
      setName("");
      setDescription("");
      setIsLoading(false);
    }
  };

  const handleBackButton = () => {
    history.push("/symptoms");
  };

  return (
    <StyledAddSymptom>
      <FormWrapper>
        <Title>添加新的症状</Title>
        <form>
          <StyledInput
            id="symptom-name-input"
            label="症状名"
            placeholder="输入症状名"
            value={name}
            error={nameError}
            onChange={(e) => setName(e.target.value)}
          />
          <StyledTextarea
            id="symptom-description-textarea"
            label="症状描述"
            rows={3}
            placeholder="输入症状描述"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <ButtonGroup>
            <Button
              shaded
              label="返回症状列表"
              onClick={handleBackButton}
              variant="border-filled"
            />
            <Button
              shaded
              label="创建新症状"
              onClick={handleAddSymptom}
              variant="brand"
              isLoading={isLoading}
              disabled={isLoading}
            />
          </ButtonGroup>
        </form>
      </FormWrapper>
    </StyledAddSymptom>
  );
};
