import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  Input,
  Textarea,
  DateTimePicker,
  CheckboxToggle,
  MultiSelect,
  Option,
} from "react-rainbow-components";
import { addEvent } from "./eventsSlice";
import { selectAllSymptoms } from "../symptoms/symptomsSlice";

const StyledAddEvent = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

const Form = styled.form`
  flex-grow: 1;
  max-width: 600px;
  flex-direction: column;
  display: flex;
  row-gap: 20px;
  @media (max-width: 600px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 5px;
  & .date-time-picker {
    flex-grow: 1;
  }
`;

const ToggleCheckboxsGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const InputsGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const ButtonsGroup = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
`;

export const AddEvent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [startDateTime, setStartDateTime] = useState(new Date().toString());
  const [endDateTime, setEndDateTime] = useState(null);
  const [isSeizure, setIsSeizure] = useState(false);
  const [isConscious, setIsConscious] = useState(false);
  const [isSleeping, setIsSleeping] = useState(false);
  const [minSpo2, setMinSpo2] = useState(0);
  const [maxHeartRate, setMaxHeartRate] = useState(0);
  const [symptoms, setSymptoms] = useState([]);
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const allSymptoms = useSelector(selectAllSymptoms);

  const handleCheckboxChange = (e) => {
    switch (e.target.name) {
      case "isSeizure":
        setIsSeizure(!isSeizure);
        break;
      case "isSleeping":
        setIsSleeping(!isSleeping);
        break;
      case "isConscious":
        setIsConscious(!isConscious);
        break;
      default:
        break;
    }
  };

  const handleCopy = () => setEndDateTime(startDateTime);
  const handleClear = () => setEndDateTime(null);

  const handleAddEvent = async (e) => {
    const event = {
      startDateTime,
      endDateTime,
      isSeizure,
      isConscious,
      isSleeping,
      minSpo2: +minSpo2,
      maxHeartRate: +maxHeartRate,
      symptoms: symptoms.map((s) => s.value),
      description,
    };
    setIsSubmitting(true);
    await dispatch(addEvent(event)).unwrap();
    setIsSubmitting(false);
    history.push("/events");
  };

  const handleBack = () => {
    history.push("/events");
  };

  return (
    <StyledAddEvent>
      <Form>
        <h1>添加事件</h1>

        <DatePickerWrapper>
          <div className="date-time-picker">
            <DateTimePicker
              value={startDateTime}
              label="开始时间"
              onChange={(value) => setStartDateTime(value)}
              hour24
            />
          </div>
          <div>
            <Button label="复制" variant="border-filled" onClick={handleCopy}/>
          </div>
        </DatePickerWrapper>
        <DatePickerWrapper>
          <div className="date-time-picker">
            <DateTimePicker
              value={endDateTime}
              label="结束时间"
              onChange={(value) => setEndDateTime(value)}
              hour24
            />
          </div>
          <div>
            <Button label="清除" variant="border-filled" onClick={handleClear}/>
          </div>
        </DatePickerWrapper>
        <ToggleCheckboxsGroup>
          <CheckboxToggle
            name="isSeizure"
            label="是明显发作"
            value={isSeizure}
            onChange={handleCheckboxChange}
          />
          <CheckboxToggle
            name="isSleeping"
            label="睡觉时发作"
            value={isSleeping}
            onChange={handleCheckboxChange}
          />
          <CheckboxToggle
            name="isConscious"
            label="有意识"
            value={isConscious}
            onChange={handleCheckboxChange}
          />
        </ToggleCheckboxsGroup>
        <InputsGroup>
          <Input
            value={minSpo2}
            label="最低血氧值"
            placeholder="最低血氧值"
            type="number"
            onChange={(e) => setMinSpo2(e.target.value)}
          />
          <Input
            label="最大心率"
            placeholder="最大心率"
            value={maxHeartRate}
            type="number"
            onChange={(e) => setMaxHeartRate(e.target.value)}
          />
        </InputsGroup>
        <div>
          <MultiSelect
            label="症状"
            placeholder="选择症状"
            value={symptoms}
            onChange={setSymptoms}
            bottomHelpText="可多选"
            variant="chip"
            showCheckbox
          >
            {allSymptoms.map(({ _id, name }) => (
              <Option key={_id} name={name} label={name} value={_id} />
            ))}
          </MultiSelect>
        </div>
        <div>
          <Textarea
            label="事件描述"
            rows={3}
            placeholder="输入事件描述"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <ButtonsGroup>
          <Button
            shaded
            label="返回事件列表"
            variant="border-filled"
            onClick={handleBack}
          />
          <Button
            shaded
            label="创建新事件"
            variant="brand"
            isLoading={isSubmitting}
            onClick={handleAddEvent}
          />
        </ButtonsGroup>
      </Form>
    </StyledAddEvent>
  );
};
