import React, { useState, useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Sidebar from "react-rainbow-components/components/Sidebar";
import SidebarItem from "react-rainbow-components/components/SidebarItem";
import ButtonIcon from "react-rainbow-components/components/ButtonIcon";
import RenderIf from "react-rainbow-components/components/RenderIf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NavigationBar from "./common/NavigationBar";
import { ApplicationIcon, DashboardIcon, MessageIcon } from "./common/icons";
import history from "./history";
import {
  toggleSidebar as getToggleSidebarAction,
  navigate as getNavigateAction,
} from "./features/app/appSlice";
import {
  selectDateStart,
  selectDateEnd,
  fetchSleepCountData,
  fetchEventCountByDay,
  fetchEventCountByWeek,
  fetchEventCountByMonth,
  fetchEventCountByHour,
  fetchEventCountBySymptom,
  fetchSeizureDurationCount,
} from "./features/dashboard/dashboardSlice";
import { fetchSymptoms } from "./features/symptoms/symptomsSlice";
import { fetchEvents } from "./features/events/eventsSlice";
import { Dashboard } from "./features/dashboard/Dashboard";
import { EventsList } from "./features/events/EventsList";
import { AddEvent } from "./features/events/AddEvent";
import { EditEvent } from "./features/events/EditEvent";
import { SymptomsList } from "./features/symptoms/SymptomsList";
import { AddSymptom } from "./features/symptoms/AddSymptom";
import { EditSymptom } from "./features/symptoms/EditSymptom";

const Backdrop = styled.div`
  @media (max-width: 600px) {
    display: unset;
    width: 100vw;
    height: 100vh;
    background-color: rgba(82, 95, 127, 0.4);
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
  }
`;

const SideNavigation = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 68px);
  margin-top: 68px;
  background-color: #fff;
  z-index: 99999;
  padding-bottom: 14px;
  padding-top: 14px;
  @media (max-width: 600px) {
    height: 100vh;
    margin-top: 0;
    transform: translateX(0%);
    transition: transform 230ms linear, opacity 230ms linear;
    transform: ${(props) =>
      props.isSidebarHidden ? "translateX(-100%)" : "translateX(0%)"};
    transition: ${(props) =>
      props.isSidebarHidden
        ? "transform 250ms linear, opacity 250ms linear"
        : "transform 230ms linear, opacity 230ms linear"};
  }
`;

const StyledSidebar = styled(Sidebar)`
  width: 88px;
  height: 100%;
  border-right: 1px solid #f4f6f9;
  overflow-y: auto;
  flex-grow: 1;
`;

const StyledSidebarItem = styled(SidebarItem)`
  margin-bottom: 16px;
`;

const BackbarButtonWrapper = styled.div`
  display: none;
  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 2;
    align-items: flex-end;
    padding-top: 12px;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #000;
`;

const MainContent = styled.div`
  padding-top: 68px;
  padding-left: 88px;
  background-color: #f4f6f9;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 600px) {
    //padding-top: 116px;
    padding-left: 0;
  }
`;

const App = () => {
  const [selectedItem, setSelectedItem] = useState("");
  const dispatch = useDispatch();
  const isSidebarHidden = useSelector((state) => state.app.isSidebarHidden);
  const dateStart = useSelector(selectDateStart);
  const dateEnd = useSelector(selectDateEnd);

  useEffect(() => {
    const selectedSidebarItem =
      history.location.pathname.split("/")[1] || "dashboard";
    setSelectedItem(selectedSidebarItem);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "auto";
    if (!isSidebarHidden) {
      document.body.style.overflow = "hidden";
    }
  }, [isSidebarHidden]);

  useEffect(() => {
    dispatch(fetchEventCountBySymptom());
    dispatch(fetchSymptoms());
    dispatch(fetchEvents());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEventCountByDay({ dateStart, dateEnd }));
    dispatch(fetchEventCountByMonth({ dateStart, dateEnd }));
    dispatch(fetchSleepCountData({ dateStart, dateEnd }));
    dispatch(fetchEventCountByWeek({ dateStart, dateEnd }));
    dispatch(fetchEventCountByMonth({ dateStart, dateEnd }));
    dispatch(fetchEventCountByHour({ dateStart, dateEnd }));
    dispatch(fetchSeizureDurationCount({ dateStart, dateEnd }));
  }, [dateStart, dateEnd, dispatch]);

  const handleOnSelect = (e, selectedItem) => {
    return setSelectedItem(selectedItem);
  };

  const toggleSidebar = () => dispatch(getToggleSidebarAction());
  const navigate = (path) => dispatch(getNavigateAction(path));

  return (
    <React.Fragment>
      <RenderIf isTrue={!isSidebarHidden}>
        <Backdrop role="presentation" onClick={toggleSidebar} />
      </RenderIf>
      <NavigationBar onToggleSidebar={toggleSidebar} />
      <SideNavigation isSidebarHidden={isSidebarHidden}>
        <StyledSidebar selectedItem={selectedItem} onSelect={handleOnSelect}>
          <StyledSidebarItem
            icon={<DashboardIcon />}
            name="dashboard"
            label="报表"
            onClick={() => navigate("/dashboard")}
          />
          <StyledSidebarItem
            icon={<MessageIcon />}
            name="events"
            label="事件"
            onClick={() => navigate("/events")}
          />
          <StyledSidebarItem
            icon={<ApplicationIcon />}
            name="symptoms"
            label="症状"
            onClick={() => navigate("/symptoms")}
          />
        </StyledSidebar>
        <RenderIf isTrue={!isSidebarHidden}>
          <BackbarButtonWrapper>
            <ButtonIcon
              onClick={toggleSidebar}
              size="large"
              icon={<StyledFontAwesomeIcon icon={faArrowLeft} />}
            />
          </BackbarButtonWrapper>
        </RenderIf>
      </SideNavigation>
      <MainContent>
        <Router history={history}>
          <Switch>
            <Route exact path="/" render={() => <Dashboard />} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/events" component={EventsList} />
            <Route exact path="/events/add" component={AddEvent} />
            <Route exact path="/events/edit/:id" component={EditEvent} />
            <Route exact path="/symptoms" component={SymptomsList} />
            <Route exact path="/symptoms/add" component={AddSymptom} />
            <Route exact path="/symptoms/edit/:id" component={EditSymptom} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </MainContent>
    </React.Fragment>
  );
};

export default App;
