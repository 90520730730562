import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";

if (window.location.hostname.indexOf("healthmemo") !== -1) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString:
        "InstrumentationKey=b8cb9f64-7be0-42ad-a111-c073df0c2934;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/",
      enableAutoRouteTracking: true,
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
