import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import ButtonIcon from "react-rainbow-components/components/ButtonIcon";
import { getUserInfo, selectUserName } from "../features/app/appSlice";
import { BarsIcon } from "./icons";

const Header = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  background-color: white;
  top: 0;
  left: 0;
  height: 68px;
  width: 100vw;
  z-index: 10000;
  border-bottom: 1px solid #f4f6f9;
  justify-content: space-between;
  @media (max-width: 600px) {
    padding: 10px 16px 0 24px;
    align-items: flex-start;
  }
`;

const LogoImage = styled.img`
  width: 48px;
  height: 48px;
`;

const HamburgerButton = styled(ButtonIcon)`
  display: none;
  @media (max-width: 600px) {
    display: inherit;
    height: 40px;
    width: 40px;
    margin-left: 16px;
  }
`;

export default function NavigationBar({ onToggleSidebar }) {
  const provider = "github";
  const redirect = window.location.pathname;
  const dispatch = useDispatch();
  const userName = useSelector(selectUserName);

  useEffect(() => {
    (() => {
      dispatch(getUserInfo());
    })();
  }, [dispatch]);

  return (
    <Header>
      <div>
        {!userName && (
          <a
            key={provider}
            href={`/.auth/login/${provider}?post_login_redirect_uri=${redirect}`}
          >
            <LogoImage src="/assets/images/slack.png" alt="logo" />
          </a>
        )}
        {userName && (
          <a href={`/.auth/logout?post_logout_redirect_uri=${redirect}`}>
            <LogoImage src="/assets/images/slack.png" alt="logo" />
          </a>
        )}
      </div>

      <HamburgerButton
        onClick={onToggleSidebar}
        size="large"
        icon={<BarsIcon />}
      />
    </Header>
  );
}

NavigationBar.propTypes = {
  onToggleSidebar: PropTypes.func,
};

NavigationBar.defaultProps = {
  onToggleSidebar: () => {},
};
