import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "react-rainbow-components";
import {
  selectAllSymptoms,
  selectSymptomById
} from "./symptomsSlice";
import { selectIsAdmin  } from "../app/appSlice";

const StyledSymptomItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: white;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 5px 10px 5px 10px;
  border-radius: 3px 5px 5px 3px;
  border: 0.0625rem solid #e3e5ed;
  border-left: 5px solid green;
  box-shadow: 0 1px 2px 0 #e3e5ed;
  min-height: 54px;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 1rem;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Description = styled.div`
  line-height: 2rem;
`;

const ButtonGroup = styled.div`
  flex: 0 0 60px;
  height: 30px;
`;

const SymptomItem = ({ _id }) => {
  const history = useHistory();
  const symptom = useSelector((state) => selectSymptomById(state, _id));
  const isAdmin = useSelector(selectIsAdmin);
  return (
    <StyledSymptomItem>
      <Name>
        <span>{symptom.name}</span>
      </Name>
      <DescriptionWrapper>
        <Description>
          <span>{symptom.description}</span>
        </Description>
        {isAdmin && <ButtonGroup>
          <Button
            variant="border"
            size="small"
            label="更改"
            onClick={() => history.push(`/symptoms/edit/${_id}`)}
          />
        </ButtonGroup>}
      </DescriptionWrapper>
    </StyledSymptomItem>
  );
};

const StyledSymptomsList = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  row-gap: 5px;
  padding: 5px;
  max-width: 1600px;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  margin-top: 12px;
  margin-bottom: 18px;
  h1 {
    margin: 0;
  }
`;

export const SymptomsList = () => {
  const history = useHistory();
  const symptoms = useSelector(selectAllSymptoms);
  const isAdmin = useSelector(selectIsAdmin);
  const loadingStatus = useSelector((state) => state.symptoms.status);

  let content;
  if (loadingStatus === "loading") {
    content = <div>Loading...</div>;
  } else {
    content = (
      <StyledSymptomsList>
        <TitleBar>
          <h1>所有症状</h1>
          {isAdmin && <div>
            <Button
              label="添加新症状"
              variant="brand"
              shaded
              onClick={() => history.push("/symptoms/add")}
            />
          </div>}
        </TitleBar>
        {symptoms.map(({ _id }) => (
          <SymptomItem key={_id} {...{ _id }} />
        ))}
      </StyledSymptomsList>
    );
  }

  return content;
};
