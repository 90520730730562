import {
    createSlice,
    createAsyncThunk,
    // createSelector,
    createEntityAdapter,
  } from "@reduxjs/toolkit";
  
  import axios from "axios";
  
  const symptomsAdapter = createEntityAdapter({
    selectId: (entity) => entity._id,
  });
  
  const initialState = symptomsAdapter.getInitialState({
    status: "idle",
    error: null,
  });
  
  export const fetchSymptoms = createAsyncThunk("symptoms/fetchSymptoms", async () => {
    const response = await axios.get("/api/symptoms");
    return response.data;
  });
  
  export const addSymptom = createAsyncThunk("symptoms/addSymptom", async (symptom) => {
    const response = await axios.post("/api/symptoms", symptom);
    const {_id, name, description } = response.data;
    return {_id, name, description };
  });
  
  export const updateSymptom = createAsyncThunk(
    "symptoms/updateSymptom",
    async (symptom) => {
      const response = await axios.put(`/api/symptoms/${symptom._id}`, symptom);
      return {id: response.data._id, changes: symptom};
    }
  );
  
  export const deleteSymptom = createAsyncThunk(
    "symptoms/deleteSymptom",
    async (id) => {
      const response = await axios.delete(`/api/symptoms/${id}`);
      return response.data._id;
    }
  );
  
  const symptomsSlice = createSlice({
    name: "symptoms",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addCase(fetchSymptoms.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchSymptoms.fulfilled, (state, action) => {
          state.status = "succeeded";
          symptomsAdapter.upsertMany(state, action.payload);
        })
        .addCase(fetchSymptoms.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        })
        .addCase(addSymptom.fulfilled, symptomsAdapter.addOne)
        .addCase(updateSymptom.fulfilled, symptomsAdapter.updateOne)
        .addCase(deleteSymptom.fulfilled, symptomsAdapter.removeOne);
    },
  });
  
  export default symptomsSlice.reducer
  
  export const { 
      selectAll: selectAllSymptoms,
      selectById: selectSymptomById,
      selectIds: selectSymptomIds,
  } = symptomsAdapter.getSelectors(state => state.symptoms)
  