import React from "react";
import styled from "styled-components";

const ChartPanel = styled.div`
  flex: 1 1 0%;
  min-width: 0;
  align-items: center;
`;

const StatisticInfo = styled.div`
  height: 150px;
`;

const Title = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

const Loading = styled.div`
  text-align: center;
`;

const StatisticItem = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1rem;
`

const Label = styled.div`
  width: 200px;
`
const BlackValue = styled.div`
  font-weight: bold;
  width: 50px;
`
const RedValue = styled.div`
  font-weight: bold;
  color: red;
  width: 50px;
`
const OrangeValue = styled.div`
  font-weight: bold;
  color: orange;
  width: 50px;
`
const GreenValue = styled.div`
  font-weight: bold;
  color: green;
  width: 50px;
`

export const StatisticBoard = ({ statisticData }) => {
  let content;
  if (statisticData.status === "succeeded") {
    const { totalDayCount, seizureDayCount, nonSeizureDayCount, goodDayCount, totalSeizureCount, totalNonseizureCount } =
      statisticData.data;
    content = (
      <div>
        <StatisticItem><Label>总天数: </Label><BlackValue>{totalDayCount}</BlackValue></StatisticItem>
        <StatisticItem><Label>有发作天数: </Label><RedValue>{seizureDayCount}</RedValue></StatisticItem>
        <StatisticItem><Label>疑似发作天数: </Label><OrangeValue>{nonSeizureDayCount}</OrangeValue></StatisticItem>
        <StatisticItem><Label>无发作，无疑似天数: </Label><GreenValue>{goodDayCount}</GreenValue></StatisticItem>
        <StatisticItem><Label>总发作次数: </Label><RedValue>{totalSeizureCount}</RedValue></StatisticItem>
        <StatisticItem><Label>总疑似发作次数: </Label><OrangeValue>{totalNonseizureCount}</OrangeValue></StatisticItem>
      </div>
    );
  } else {
    content = <Loading>加载中......</Loading>;
  }

  return (
    <ChartPanel>
      <Title>统计信息</Title>
      <StatisticInfo>{content}</StatisticInfo>
    </ChartPanel>
  );
};
