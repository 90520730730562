import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { Input } from "react-rainbow-components";
import { Textarea } from "react-rainbow-components";
import { Button } from "react-rainbow-components";
import { Popconfirm } from "antd";
import {
  updateSymptom,
  selectSymptomById,
  deleteSymptom,
} from "./symptomsSlice";
const StyledEditSymptom = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 1.4rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 600px;
  @media (max-width: 600px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const StyledInput = styled(Input)`
  max-width: 600px;
  margin-bottom: 10px;
`;

const StyledTextarea = styled(Textarea)`
  max-width: 600px;
  margin-bottom: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    column-gap: 10px;
  }
`;

export const EditSymptom = () => {
  const { id } = useParams();
  const symptom = useSelector((state) => selectSymptomById(state, id));
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (symptom) {
      setName(symptom.name);
      setDescription(symptom.description);
    }

    if (!symptom) {
      history.push("/symptoms");
    }
  }, [symptom, history]);

  const handleEditSymptom = async () => {
    if (!name) {
      setNameError("症状名不能为空");
    } else {
      nameError && setNameError("");
      setIsLoading(true);
      await dispatch(updateSymptom({ _id: id, name, description })).unwrap();
      setIsLoading(false);
      history.push("/symptoms");
    }
  };

  const handleBackButton = () => {
    history.push("/symptoms");
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    await dispatch(deleteSymptom(id));
  };

  return (
    <StyledEditSymptom>
      <FormWrapper>
        <Title>编辑症状</Title>
        <form>
          <StyledInput
            id="symptom-name-input"
            label="症状名"
            placeholder="输入症状名"
            value={name}
            error={nameError}
            onChange={(e) => setName(e.target.value)}
          />
          <StyledTextarea
            id="symptom-description-textarea"
            label="症状描述"
            rows={3}
            placeholder="输入症状描述"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <ButtonGroup>
            <div>
              <Popconfirm
                title="确认删除这个症状吗?"
                onConfirm={handleDelete}
                okText="确认"
                cancelText="取消"
              >
                <Button
                  label="删除症状"
                  variant="destructive"
                  isLoading={isDeleting}
                />
              </Popconfirm>
            </div>
            <div>
              <Button
                shaded
                label="返回症状列表"
                onClick={handleBackButton}
                variant="border-filled"
              />
              <Button
                shaded
                label="保存"
                onClick={async () => await handleEditSymptom()}
                variant="brand"
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          </ButtonGroup>
        </form>
      </FormWrapper>
    </StyledEditSymptom>
  );
};
